<template>
    <div class="card">
        <div class="card-header border-0 pt-6 bg-white row m-0">
            <div class="p-0">
                <span class="text-gray-500 fw-bolder text-hover-primary rounded-1 cursor-pointer me-6 fs-6" @click="router.push({ name: 'product_raw' })"><i class="fas fa-arrow-left me-2 text-gray-500 fw-bolder"></i>BACK</span>

                <div class="d-flex p-0">
                    <div class="card-title">
                        <div class="filter d-flex align-items-center position-relative fs-6 text-gray-600">               
                            <div class="filter-item">
                                <span>Search</span>
                                <el-input v-model="filter.search" @change="onFilter()" @keyup.enter="onFilter()" @clear="onFilter()" placeholder="Search" clearable style="width: 200px;" />
                            </div>
                            <div class="d-flex">
                                <button class="btn btn-icon btn-light-primary me-3" @click="onFilter()"><i class="fas fa-search"></i></button>
                                <button class="btn btn-icon btn-light-danger" @click="resetFilter()"><i class="fas fa-times"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="card-toolbar" style="margin-left:auto">
                        <div class="row g-2 justify-content-end" data-kt-table-toolbar="base">
                            <button class="btn btn-primary" @click="getCreate()">Create</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body pt-0 tab-content">
            <div class="table-fixed-header" ref="tableRef">
                <Datatable 
                    :table-header="tableHeader" 
                    :table-data="tableData"
                    :loading="loadingTable"
                    :total="totalPage"
                    :rows-per-page="50"
                    :enable-items-per-page-dropdown="false"
                >
                    <template v-slot:cell-index="{ row: data }">
                        <span>{{ data.index }}</span>
                    </template>
                    <template v-slot:cell-name="{ row: data }">
                        {{ data.name }}
                    </template>
                    <template v-slot:cell-detail="{ row: data }">
                        {{ data.detail }}
                    </template>
                    <template v-slot:cell-price="{ row: data }">
                        {{ formatIDR(data.price) }}
                    </template>
                    <template v-slot:cell-created_at="{ row: data }">
                        {{ (data.created_at) }}
                    </template>
                    <template v-slot:cell-action="{ row: data }">
                        <div class="d-flex gs-1 jsutify-end">
                            <div class="col">
                                <button class="btn btn-secondary btn-sm me-1" title="Edit" @click="getRow(data)" >
                                    <i class="fas fa-edit"></i>
                                </button>
                            </div>
                        </div>
                    </template>
                </Datatable>
            </div>
            <div class="text-center my-3" v-show="tableData.length > 0">
                <el-pagination 
                    background 
                    :page-size="50"
                    layout="prev, pager, next" 
                    :page-count="pagination.total_page" 
                    @next-click="handlePageChange" 
                    @prev-click="handlePageChange" 
                    @current-change="handlePageChange" 
                    @update:current-page="pagination.page"
                />
            </div>
        </div>
        <el-dialog v-model="modal" :title="modalTitle + ' Print Size'" width="600px" :close-on-click-modal="true">
            <Form
                class="form w-100"
                :validation-schema="tempData"
                @submit="onSubmit(submitType)"
            >
                <input type="hidden" name="id" v-model="tempData.id" disabled/>
                <div class="scroll-y me-n7 pe-7">
                     <div class="fv-row mb-7">
                        <label class="required fs-6 fw-bold mb-2">Nama</label>
                        <Field v-model="tempData.name" type="text" name="name" v-slot="{ field }">
                            <input v-bind="field" class="form-control"/>
                        </Field>
                        <div class="fv-plugins-message-container">
                            <div class="fv-help-block">
                                <ErrorMessage name="name" />
                            </div>
                        </div>
                    </div>
                    <div class="fv-row mb-7">
                        <label class="required fs-6 fw-bold mb-2">Deskripsi</label>
                        <Field v-model="tempData.detail" type="text" name="detail" v-slot="{ field }">
                            <input v-bind="field" class="form-control"/>
                        </Field>
                        <div class="fv-plugins-message-container">
                            <div class="fv-help-block">
                                <ErrorMessage name="detail" />
                            </div>
                        </div>
                    </div>
                    <div class="fv-row mb-7">
                        <label class="required fs-6 fw-bold mb-2">Harga</label>
                        <Field v-model="tempData.price" type="text" name="price" v-slot="{ field }">
                            <input v-bind="field" class="form-control"/>
                        </Field>
                        <div class="fv-plugins-message-container">
                            <div class="fv-help-block">
                                <ErrorMessage name="price" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-4 mb-2 text-center">
                    <button type="reset" class="btn btn-light me-3" @click="modal = false">Cancel</button>
                    <button
                        type="submit"
                        ref="submitButton"  
                        class="btn btn-primary"
                    >
                        <span class="indicator-label"> Submit </span>

                        <span class="indicator-progress">
                            Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                </div>
            </Form>
        </el-dialog>

        <el-dialog v-model="viewImageVisible" :title="viewImageTitle" width="50%" lock-scroll>
            <inner-image-zoom :src="viewImageSrc" />
        </el-dialog>
    </div>
</template>

<script>
import { defineComponent, onMounted, ref, reactive, watchEffect, watch, computed, onUpdated } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatIDR } from "@/helpers/functions";
//import TableLite from 'vue3-table-lite/src/components/TableLite.vue'
import Datatable from "@/components/kt-datatable/KTDatatable.vue"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import InnerImageZoom from 'vue-inner-image-zoom';
import { InfoFilled } from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox, ElLoading, Elpagination } from 'element-plus'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
    components: { 
        Datatable,
        Field,
        Form,
        ErrorMessage,
        'inner-image-zoom': InnerImageZoom,
    },
    setup() {
        const route = useRoute();
        const router = useRouter();

        const img_url_mockup = ref(process.env.VUE_APP_IMAGE_MOCKUPS)
        const img_url_desain= ref(process.env.VUE_APP_IMAGE_DESAINS)
        const img_url_logo= ref(process.env.VUE_APP_IMAGE_LOGOS)

        const loadingTable = ref(false);
        const tableRef = ref(null)
        const modal = ref(null)
        const modalTitle = ref('Create')
        const submitType = ref('create')

        const filterActive = ref(false)
        const filter = reactive({
            tipe: null,
            search: null,
        })
        
        const tableHeader = ref([
            {
                name: "#",
                key: "index",
            },
            {
                name: "Nama",
                key: "name",
            },
            {
                name: "Deskripsi",
                key: "detail",
            },
            {
                name: "Harga",
                key: "price",
            },
            {
                name: "Created At",
                key: "created_at",
            },
            {
                name: "Aksi",
                key: "action",
            },
        ]);

        const tableData = reactive([]);

        const tempData = reactive(Yup.object().shape({
            id: null,
            name: Yup.string().required().label('Nama').nullable(true),
            detail: Yup.string().required().label('Detail').nullable(true),
            price: Yup.string().required().label('Harga').nullable(true),
        }))

        const listRawCategory = reactive([]);
        
        const getRow = async(data = null) => {
            try {
                modalTitle.value = 'Edit';
                submitType.value = 'update';
                await Object.assign(tempData, data);
             
                modal.value = true

            } catch(e) {
                console.log(e)
            }
        }

        const getCreate = async() => {
            try {
                modalTitle.value = 'Create';
                submitType.value = 'create';

                tempData.name = ''
                tempData.detail = ''
                tempData.price = ''

                modal.value = true

            } catch(e) {
                console.log(e)
            }
        }

        const onFilter = async (type) => {
            if(Object.values(filter).every(o => o == null || o.length == 0)) {
                //return
            }

            filterActive.value = false
        
            loadingTable.value = true

            await getData()

            loadingTable.value = false

            Object.values(filter).every(o => o == null || o.length == 0)
                ? filterActive.value = false
                : filterActive.value = true
        }

        const resetFilter = async (type) => {
          if(Object.values(filter).every(o => o == null || o.length == 0)) {
            //return
          }

          Object.keys(filter).map(key => filter[key] = null)

          loadingTable.value = true
          filterActive.value = false

          await getData()

          loadingTable.value = false
        }

        // PAGINATION
        const pagination = ref({
            per_page: 50,
            total_page: 0,
            total_row: 0,
            page: 1,
        })

        const handlePageChange = async (page) => {
            loadingTable.value = true
            tableRef.value && (tableRef.value.scrollTop = 0) // Reset scroll position

            await getData(page)
            loadingTable.value = false
        }

        const getData = async (page = 1) => {
            try {
                loadingTable.value = true
                await tableData.splice(0)

                let search = filter.search ? '&search='+filter.search : '';

                await ApiService.get("print_size/list?page=" + page + search)
                .then(async ({ data }) => {
                    pagination.value = data.data.pagination
                    Object.assign(tableData, data.data.data)
                })
                .catch((response) => {
                    throw new Error(response)
                });

            } catch(e) {
                console.log(e)
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
            
            loadingTable.value = false
        }

        const onSubmit = async(type) => {
            const loading = ElLoading.service({text: 'tunggu'})

            try {

                if(type.toLowerCase() == 'create') {
                    await ApiService.post('print_size/create', tempData)
                    .then(async({ data }) => {
                        if(data.error) {
                            throw new Error(data.messages)
                        }

                        Swal.fire({
                            title: data.messages,
                            icon: "success",
                            buttonsStyling: false,
                            showConfirmButton: false,
                            timer: 2000,
                        })

                        loading.close()
                        modal.value = false

                        await getData()
                    })
                    .catch((response) => {
                        throw new Error(response)
                    });

                } else if(type.toLowerCase() == 'update') {
                    await ApiService.post('print_size/update/'+tempData.id, tempData)
                    .then(async({ data }) => {
                        if(data.error) {
                            throw new Error(data.messages)
                        }

                        Swal.fire({
                            title: data.messages,
                            icon: "success",
                            buttonsStyling: false,
                            showConfirmButton: false,
                            timer: 2000,
                        })

                        loading.close()
                        modal.value = false

                        await getData()
                    })
                    .catch((response) => {
                        throw new Error(response)
                    });
                } else {
                    throw new Error('Submit is invalid type')
                }
                

            } catch(e) {
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }

            loading.close()
        }

        const viewImageSrc = ref(null)
        const viewImageTitle = ref('')
        const viewImageVisible = ref(false)

        const viewImage = (data) => {
            viewImageTitle.value = data.logo
            viewImageVisible.value = true
            viewImageSrc.value = img_url_logo.value+data.logo
        }

        onMounted(() => {
            setCurrentPageBreadcrumbs(route.meta.title, [route.meta.title]);
            getData()
        })

        return {
            route, router,            
            formatIDR,
            loadingTable, tableRef, img_url_mockup, img_url_desain, img_url_logo,
            viewImage, viewImageSrc, viewImageVisible, viewImageTitle,
            modal, modalTitle, getRow, getCreate,
            pagination, handlePageChange,
            onFilter, resetFilter, filter, filterActive,
            listRawCategory,
            tableHeader, tableData, tempData, onSubmit, submitType,
        }
    }
})
</script>